import * as React from 'react'
import { Formik, Field, Form, withFormik } from 'formik'
import * as Yup from 'yup'
import Button from 'react-bulma-components/lib/components/button'
import Box from 'react-bulma-components/lib/components/box'
import Notification from 'react-bulma-components/lib/components/notification'
import firebase from '../firebase'
import env from '../env'
import { Link } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';

import * as BulmaForm from 'react-bulma-components/lib/components/form'

const sendWebAuth = firebase.functions().httpsCallable('sendWebAuth')
const authUser = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password).catch(err => {
        console.log(err)
        return Promise.reject(err.message)
    })
}

const sendMagicLink = (email) => {
    window.localStorage.setItem('emailForSignIn', email)
    return sendWebAuth({
        email
    })
    /* return firebase.auth().sendSignInLinkToEmail(email, env.magicLink)
     *     .then(() => {
     *         window.localStorage.setItem('emailForSignIn', email)
     *     })
     *     .catch((err) => {
     *         return Promise.reject(err.message || 'There was a problem submitting')
     *     }) */
}

const TextInput = ({ type, id, label, error, value, disabled, onChange, onBlur, ...props }) => {
    return (
        <BulmaForm.Field>
            <BulmaForm.Label>{label}</BulmaForm.Label>
            <BulmaForm.Control>
                <BulmaForm.Input
                    name={id}
                    value={value}
                    {...props}
                    color={error ? 'danger' : null}
                    onBlur={onBlur}
                    disabled={disabled}
                    onChange={onChange}
                    type={type}
                    placeholder="hello@example.com" />
            </BulmaForm.Control>
            {error && <BulmaForm.Help color="danger">{error}</BulmaForm.Help>}
        </BulmaForm.Field>
    )
}

interface IState {
    sent: boolean,
    loading: boolean,
    error: string
}

interface IProps {
    loading: boolean
}
class Login extends React.Component<IProps, IState> {
    state = {
        sent: false,
        loading: true,
        error: '',
    }
    componentDidMount() {
        // Check if we are handling a magic link
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn')
            if (!email) {
                email = window.prompt('Please provide your email for confirmation');
            }
            if (email) {
                firebase.auth().signInWithEmailLink(email, window.location.href)
                    .then((res) => {
                        sessionStorage['emailForSignIn'] = email
                        window.localStorage.removeItem('emailForSignIn')
                        this.setState({ loading: false })
                    })
                    .catch((err) => {
                        this.setState({
                            loading: false,
                            error: err.message || 'Unable to process login'
                        })
                    })
            }
        } else {
            this.setState({ loading: false })
        }
    }

    renderForm() {
        return (<Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={Yup.object().shape({
                email: Yup.string().email().required(),
            })}
            onSubmit={(values, actions) => {
                sendMagicLink(values.email).then(res => {
                    actions.setSubmitting(false)
                    this.setState({ sent: true })
                }).catch(err => {
                    console.log(JSON.stringify(err))
                    actions.setErrors({ email: 'Unable to send login email' })
                    actions.setSubmitting(false)
                })
            }}
            render={props => {
                const { values, handleChange, handleBlur, handleSubmit, isSubmitting, touched, errors } = props
                const { error } = this.state
                const { loading } = this.props
                return (
                    <form onSubmit={handleSubmit}>
                        {error.length > 0 && <Notification color="danger">{error}</Notification>}
                        {!loading && <TextInput
                            id="email"
                            type="email"
                            label="Email"
                            autoCorrect="off"
                            autoCapitalize="none"
                            error={touched.email && errors.email}
                            disabled={isSubmitting || loading}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur} />}
                        <Button fullwidth color="primary" loading={isSubmitting || loading} className="is-medium" type="submit">Send Login Link</Button>
                    </form>)
            }} />)
    }

    renderSent() {
        return (<Notification color="success">
            A login link has been sent to your email address. Click the link in your email to continue.
        </Notification>)
    }

    render() {
        const { sent } = this.state
        return (
            <div className="login-page">
                <div className="login">
                    <h2>Login</h2>
                    {sent ? this.renderSent() : this.renderForm()}
                    {!sent && <Link to="/sign-up">Don't have an account? Create a new account</Link>}
                </div>
            </div>
        )
    }
}

export default Login
