import * as React from 'react'
import { Formik, Field, Form, withFormik } from 'formik'
import * as Yup from 'yup'
import Button from 'react-bulma-components/lib/components/button'
import Box from 'react-bulma-components/lib/components/box'
import Notification from 'react-bulma-components/lib/components/notification'
import firebase from '../firebase'
import { Link } from "react-router-dom";

import * as BulmaForm from 'react-bulma-components/lib/components/form'

interface IState {
    sent: boolean,
    loading: boolean,
    error: string
}

const RegisterTextInput = ({ type, disabled, id, label, error, value, onChange, onBlur, placeholder }) => {
    return (
        <BulmaForm.Field>
            <BulmaForm.Label>{label}</BulmaForm.Label>
            <BulmaForm.Control>
                <BulmaForm.Input
                    name={id}
                    value={value}
                    color={error ? 'danger' : null}
                    onBlur={onBlur}
                    onChange={onChange}
                    disabled={disabled}
                    type={type}
                    placeholder={placeholder} />
            </BulmaForm.Control>
            {error && <BulmaForm.Help color="danger">{error}</BulmaForm.Help>}
        </BulmaForm.Field>
    )
}

class Register extends React.Component<any, IState> {
    state = {
        sent: false,
        loading: true,
        error: '',
    }

    componentDidMount() {
        this.setState({ loading: false })
    }

    renderForm() {
        return (<Formik
            initialValues={{ email: '', firstName: '', lastName: '', companyName: '', phoneNumber: '' }}
            validationSchema={Yup.object().shape({
                email: Yup.string().email('Email must be a valid email address').required('Email is required'),
                firstName: Yup.string().required('First Name is required'),
                lastName: Yup.string().required('Last Name is required'),
                phoneNumber: Yup.string().required('Phone number is required'),
                companyName: Yup.string().required('Compnay Name is required'),
            })}
            onSubmit={async (values, actions) => {
                actions.setSubmitting(true)
                this.setState({ error: '' })
                try {
                    const res = await fetch(global.apiRoot + '/api/register', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(values)
                    })
                    const json = await res.json()
                    if (res.status !== 200) {
                        this.setState({ error: json.message })
                        actions.setSubmitting(false)
                    } else {
                        this.setState({ sent: true })
                        window.localStorage.setItem('emailForSignIn', values.email)
                        window.location.href = String('https://www.equappment.com/sign-up-welcome')
                    }
                } catch (e) {
                    actions.setSubmitting(false)
                }
                {/* window.localStorage.setItem('emailForSignIn', values.email)
                    this.setState({ sent: true })
                    actions.setSubmitting(false) */}
            }}
            render={props => {
                const { values, handleChange, handleBlur, handleSubmit, isSubmitting, touched, errors } = props
                const { error, loading } = this.state
                return (
                    <form onSubmit={handleSubmit}>
                        {error.length > 0 && <Notification color="danger">{error}</Notification>}
                        {!loading && <RegisterTextInput
                            id="email"
                            type="email"
                            label="Email"
                            placeholder="hello@example.com"
                            disabled={isSubmitting}
                            error={touched.email && errors.email}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur} />}
                        {!loading && <RegisterTextInput
                            id="firstName"
                            type="text"
                            label="First Name"
                            placeholder="First"
                            disabled={isSubmitting}
                            error={touched.firstName && errors.firstName}
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur} />}
                        {!loading && <RegisterTextInput
                            id="lastName"
                            type="text"
                            label="Last Name"
                            placeholder="Last"
                            disabled={isSubmitting}
                            error={touched.lastName && errors.lastName}
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur} />}
                        {!loading && <RegisterTextInput
                            id="phoneNumber"
                            type="text"
                            label="Phone Number"
                            disabled={isSubmitting}
                            placeholder="123-123-1234"
                            error={touched.phoneNumber && errors.phoneNumber}
                            value={values.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur} />}
                        {!loading && <RegisterTextInput
                            id="companyName"
                            type="text"
                            label="Company Name"
                            disabled={isSubmitting}
                            placeholder="Your Company, Inc"
                            error={touched.companyName && errors.companyName}
                            value={values.companyName}
                            onChange={handleChange}
                            onBlur={handleBlur} />}
                        <Button fullwidth color="primary" loading={isSubmitting || loading} className="is-medium" type="submit">Sign Up</Button>
                    </form>)
            }} />)
    }

    render() {
        const { sent } = this.state
        return (
            <div className="register-page">
                <div className="register">
                    <h2>Sign Up for a Free Trial</h2>
                    {this.renderForm()}
                    {!sent && <Link to="/">Already have an account? Log In</Link>}
                </div>
                <ul>
                    <li>Sign up to get your company on the Equappment app</li>
                    <li>Automatically start with 30 days free to get your company on board and your data entered</li>
                    <li>Just 1 sign-up required. Invite others from within the app</li>
                </ul>
            </div>
        )
    }

}

export default Register
