import * as React from 'react';
import { Formik, Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import Button from 'react-bulma-components/lib/components/button';
import Box from 'react-bulma-components/lib/components/box';
import Notification from 'react-bulma-components/lib/components/notification';
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';
import env from '../env';
import '../sass/subscribe.sass';
import AppPreview from './AppPreview';
const barsImg = require("./../assets/img/bars.png");
import * as jQuery from 'jquery';

import * as BulmaForm from 'react-bulma-components/lib/components/form'

interface IState {
    sent: boolean,
    loading: boolean,
    error: string,
    subscriptionUpdateStatus: string,
    submitInProgress: boolean,
    setupLoading: boolean,
    coupon: string
}
class Subscribe extends React.Component<any, IState> {

    state = {
        user: null,
        sent: false,
        loading: true,
        error: '',
        subscriptionUpdateStatus: '',
        submitInProgress: false,
        setupLoading: false,
        coupon: '',
        subscription: null
    }

    componentDidMount() {
        if (!window.recurly) {
            this.setState({ error: 'Could not connect to payment processor' })
        } else {
            recurly.configure({
                publicKey: env.recurly.publicKey,
                fields: {
                    // affects all fields
                    all: {
                        style: {
                            placeholder: {
                            }
                        }
                    },

                    // affects the combined card field
                    card: {
                        displayIcon: true,
                        style: {
                            fontSize: '10px',
                            placeholder: {
                                color: 'gray !important',
                                fontWeight: 'bold',
                                content: {
                                    number: 'Card number',
                                    cvv: 'CVC'
                                }
                            },
                            invalid: {
                                fontColor: 'red'
                            }
                        }
                    },

                    // affects individual card field types
                    number: {
                        // Custom target selector
                        selector: '#recurly-number',
                        // Format the card number
                        format: true
                    },
                    month: {
                        // Display a month select on mobile devices
                        inputType: 'mobileSelect',
                        style: {
                            placeholder: {
                                content: 'MM'
                            }
                        }
                    },
                    year: {
                        style: {
                            placeholder: {
                                content: 'YYYY'
                            }
                        }
                    },
                    cvv: {}
                }
            });

            recurly.on('change', state => {
              // state.fields.number.empty;
            });
        }
    }

    setSubStatus(statstr) {
        var statMsg = '';
        if (statstr === 'new') {
            statMsg = "Thank you for subscribing. Your account is now active. You may now use the App";
        } else if (statstr === 'updated') {
            statMsg = "Your subscription information has been successfully updated";
        } else if (statstr === 'declined') {
            statMsg = " Your card was declined. Please check your payment information and try again.";
        } else if (statstr === 'processor') {
            statMsg = "We had an error processing your card. Please try again later or contact support.";
        } else if (statstr === 'error') {
            statMsg = "We had a problem processing your card. Please try again later or contact support.";
        } else {
            statMsg = statstr;
        }
        this.setState({ subscriptionUpdateStatus: statMsg, submitInProgress: false });
    }

    couponChange = (event) => {
      this.setState({coupon: event.target.value});
    }

    handleSubmit(event, user_id, coupon) {
        event.preventDefault();
        var self = this;

        recurly.token(document.getElementById('recurlyForm'), function(err, recurlyToken) {
            self.setState({ submitInProgress: true });
            jQuery('.recurly-hosted-field').removeClass('error');
            jQuery('.card-field').removeClass('error');
            if (err) {
                // Error

                jQuery.each(err.fields, function (i, field) {
                    jQuery('[data-recurly=' + field + ']').addClass('error');
                    if (field == 'number' || field == 'month' || field == 'year') {
                        jQuery('.card-field').addClass('error');
                    }
                });

                if (err.message) {
                    self.setSubStatus(err.message);
                } else {
                    self.setSubStatus('error');
                }
                console.log(err);
            } else {
                axios.post(global.apiRoot + '/api/subscribe',
                    {
                        recurly_token: recurlyToken.id,
                        user_id: user_id,
                        coupon: coupon
                    },
                    {
                        headers: {
                            'Accept': 'application/json',
                        }
                    }).then(response => {
                        if (response.data.message == 'success') {
                            console.log(response.data)
                            window.location.href = String('https://www.equappment.com/thank-you');
                            // self.props.refresh()
                            /* window.location.reload(); */
                            /* self.setSubStatus('new'); */
                        } else {
                            // Error
                            console.log(response.data.error)
                            self.setSubStatus('error');
                            if (typeof response.data.error.errors.error.$.field !== 'undefined') {
                                if (response.data.error.errors.error.$.field == 'subscription.coupon_code') {
                                    self.setSubStatus('Your coupon code is invalid.');
                                }
                            }
                        }
                    }).catch(error => {
                        // Error
                        console.log("\nsubscribe ERROR: \n", error,
                            "\nerror.response\n", error.response);
                        self.setSubStatus('error');
                    });

            }
        });
        self.setState({ submitInProgress: false });

    }

    createMarkup() {
        return { __html: '<input type="text" class="recurly-hosted-field" data-recurly="first_name" placeholder="First Name"><input type="text" class="recurly-hosted-field" data-recurly="last_name" placeholder="Last Name"><br><input type="text" class="recurly-hosted-field" data-recurly="address1" placeholder="Address"><br><input type="text" class="recurly-hosted-field" data-recurly="city" placeholder="City"><input type="text" class="recurly-hosted-field" data-recurly="state" placeholder="State"><br><input type="text" class="recurly-hosted-field" data-recurly="postal_code" placeholder="Postal Code"><input type="text" class="recurly-hosted-field" data-recurly="country" placeholder="Country" value="US"><div data-recurly="card"></div><input type="hidden" name="recurly-token" data-recurly="token"><button>submit</button>' };
    };

    render() {
        const { sent } = this.state
        return (
            <div className="subscribe">
                <LoadingOverlay active={this.state.submitInProgress} spinner text='Processing...'>
                    <div className="yellowBubble">
                        Thank you for trying Equappment. All your data is still in tact, and waiting for you. We hope you've found our app to be useful, and that you will make this a long term thing!
                    </div>
                    <h1>Start Your Subscription</h1>
                    {(this.props.subscription.tier == 'tier10') &&
                        <h2>$89 / month after your 30-day free trial</h2>
                    }
                    {(this.props.subscription.tier == 'tier40') &&
                        <h2>$229 / month after your 30-day free trial</h2>
                    }
                    {(this.props.subscription.tier == 'tierinf') &&
                        <h2>$389 / month after your 30-day free trial</h2>
                    }
                    <h4>Cancel any time. Risk free</h4>
                    {this.state.subscriptionUpdateStatus != '' &&
                        <div className="subStatus">
                            {this.state.subscriptionUpdateStatus}
                        </div>
                    }
                    <LoadingOverlay active={this.state.setupLoading} spinner text='Connecting to payment processor...'>
                        <form onSubmit={event => this.handleSubmit(event, this.props.user.uid, this.state.coupon)} id="recurlyForm">
                            <input type="text" className="input recurly-hosted-field col-sm-6" data-recurly="first_name" placeholder="First Name" />
                            <input type="text" className="input recurly-hosted-field col-sm-6" data-recurly="last_name" placeholder="Last Name" /><br />
                            <input type="text" className="input recurly-hosted-field col-sm-6" data-recurly="address1" placeholder="Address" />
                            <input type="text" className="input recurly-hosted-field col-sm-6" data-recurly="city" placeholder="City" /><br />
                            <input type="text" className="input recurly-hosted-field col-sm-6" data-recurly="state" placeholder="State" /><br />
                            <input type="text" className="input recurly-hosted-field col-sm-6" data-recurly="postal_code" placeholder="Postal Code" />
                            <input type="text" className="input recurly-hosted-field col-sm-6" data-recurly="country" placeholder="Country" defaultValue="US" />
                            <div className="card-field">
                                <div data-recurly="card"></div>
                            </div>
                            <input type="hidden" name="recurly-token" data-recurly="token" />

                            <input type="text" className="input recurly-hosted-field" onChange={this.couponChange} placeholder="Coupon Code (optional)" />

                            <button>submit</button>
                        </form>
                    </LoadingOverlay>
                    <ul>
                        <li>You and 9 other employees will have access to all features of Equappment</li>
                        <li>Enter your entire fleet of equipment and tools –whatever you want to keep track of</li>
                        <li>Unlimited Job and Bid creation</li>
                        <li>Keep track of unlimited Crew Members</li>
                        <li>If you exceed 10 “Equappers” your account will automatically be bumped up to the next subscription tier at $229 per month (That’s a good thing! It means your biz is growing!)</li>
                        <li>Your subscription will renew each month, but we’ll always email you an invoice for those uptight accountants</li>
                        <li>If you ever get tired of keeping track of your Equipment and Jobs, you can cancel at any time.</li>
                        <li>Need some help getting started? We’re here for you. Just look for the in-app support options or email us at <a href="mailto:support@equappment.com">support@equappment.com</a></li>
                    </ul>
                </LoadingOverlay>
            </div>
        )
    }

}

export default Subscribe
