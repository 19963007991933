import * as React from 'react';
import Button from 'react-bulma-components/lib/components/button';
import Level from 'react-bulma-components/lib/components/level';
import Notification from 'react-bulma-components/lib/components/notification';
import firebase from '../firebase';
import PreviewTable from './Table';
import Upload from './Upload';
import Success from './Success';
import Instructions from './Instructions';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

const bulkImport = firebase.functions().httpsCallable('bulkImport')
const sendJobReport = firebase.functions().httpsCallable('sendJobReport')
const sendImporterAuth = firebase.functions().httpsCallable('sendImporterAuth')

interface IState {
    data: any[]
    loading: boolean
    success: boolean
    numImported: number
    numDuplicate: number
    error: string
}
class Importer extends React.Component<any, IState> {
    state = {
        data: [],
        loading: false,
        success: false,
        numImported: 0,
        numDuplicate: 0,
        error: ''
    }

    upload = (data) => {
        this.setState({ loading: true, success: false, error: '' })
        bulkImport(data).then((res: any) => {
            this.setState({
                loading: false,
                success: true,
                numImported: res.data.num,
                numDuplicate: res.data.numDuplicate,
                data: []
            })
        }).catch(err => {
            this.setState({ loading: false, error: err.message })
        })
    }

    renderContent() {
        const { data, success, numImported, numDuplicate } = this.state
        if (success) {
            return <Success numItems={numImported} numDuplicate={numDuplicate} />
        }
        if (data.length > 0) {
            return <PreviewTable data={this.state.data} />
        }
        return <Instructions />
    }

    render() {
        const { data, loading, success, error } = this.state
        if (this.props.user) {
            // Only check for User for now.
            // if (this.props.user && (this.state.subscription == 'tier10' || this.state.subscription == 'tier40' || this.state.subscription == 'tierinf')) {
            return (
                <div className="row justify-content-center">
                    <div className="importer col-md-8">
                        <h1>Bulk Equipment Import</h1>
                        <Level>
                            <Level.Side align="left">
                                <Upload onLoaded={d => this.setState({ data: d, success: false })} />
                            </Level.Side>
                            <Level.Side align="left">
                                {error && <Notification color="danger" style={{ marginBottom: 0 }}>
                                    <i className="fas fa-exclamation-triangle "></i>
                                    {error}
                                </Notification>}
                                <Button color="primary"
                                    disabled={data.length === 0}
                                    loading={loading}
                                    onClick={(e) => {
                                        this.upload(data)
                                    }}>Import</Button>
                            </Level.Side>
                        </Level>
                        {this.renderContent()}
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    Denied
                </div>
            )
        }
    }
}

export default Importer
